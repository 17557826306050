import { Add } from "@mui/icons-material";
import { Button, CircularProgress, Divider, Grid, Pagination, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PaperTableOptions, PaperTableOptionsProps } from "./PaperTableOptions";
import { useUpdateSearchParams } from "../../hooks/useUpdateSearchParams";

type PaperTableProps = {
  title: string
  addLink?: string
  loading?: boolean
  headers: Array<string> // Pode ser um objeto também. Dessa forma daria para trabalhar com sort
  columns: Array<{
    key: string
    render?: (val: any, data?: any) => any
  }>
  data: Array<any>
  options?: PaperTableOptionsProps['items']
  pagination?: PaperTablePaginationProps
}

export function PaperTable(props: PaperTableProps) {
  const navigate = useNavigate()

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
          <Typography component="h2" variant="h6" color="primary" gutterBottom>{props.title}</Typography>
          {props.addLink ? (
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => navigate(`${props.addLink}`)}
            >
              Adicionar
            </Button>
          ) : null}
        </Grid>
        {props.loading ? (
          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        ) : (
          <Table size="small">
            <TableHead>
              <TableRow>
                {props.headers.map((header, headerIdx) => (
                  <TableCell key={`header-${headerIdx}`}>{header}</TableCell>
                ))}
                {props.options ? (
                  <TableCell>Opções</TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((data, dataIdx) => (
                <TableRow key={`table-row-${dataIdx}`}>
                  {props.columns.map((column, columnIdx) => {
                    return (
                      <TableCell key={`table-cell-${columnIdx}`}>
                        {column.render ? column.render(data[column.key], data) : data[column.key]}
                      </TableCell>
                    )
                  })}
                  {props.options ? (
                    <PaperTableOptions items={props.options} data={data} />
                  ) : null}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        {props.pagination ? (
          <PaperTablePagination 
            currentPage={props.pagination.currentPage}
            totalPages={props.pagination.totalPages}
            totalResults={props.pagination.totalResults}
            onPageChange={props.pagination.onPageChange}
          />
        ) : null}

      </Paper>
    </Grid>
  )
}

type PaperTablePaginationProps = {
  currentPage: number
  totalPages: number
  totalResults: number
  onPageChange?: (page: number) => void
}

function PaperTablePagination(props: PaperTablePaginationProps) {
  const {setQueryParam} = useUpdateSearchParams()
  
  return (
    <>
      <Divider />
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="body2"
          >
            Exibindo página {props.currentPage} de {props.totalPages} de {props.totalResults} registros
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} display="flex" justifyContent="flex-end">
          <Pagination
            defaultPage={props.currentPage}
            count={props.totalPages}
            size="small"
            onChange={(_, page) => {
              setQueryParam({ page })
              
              if (props.onPageChange) {
                props.onPageChange(page)
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

