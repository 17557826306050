import { gql, useQuery } from "@apollo/client";

type StatesResponse = {
  states: Array<{
    id: string
    name: string
  }>
}

const stateQuery = gql`
  {
    states {
      id
      name
    }
  }
`

export function useGetStates() {
  const { data, loading, error } = useQuery<StatesResponse>(stateQuery)

  return {
    statesData: data,
    isStatesLoading: loading,
    statesError: error,
  }
}