import { Breadcrumbs, Grid, Link, Paper, Typography } from "@mui/material";
import { Wrapper } from "../../components/Wrapper";
import { PaperTable } from "../../components/PaperTable";
import { useSearchParams } from "react-router-dom";
import { CustomButton } from "../../components/CustomButton";
import { FilterDate } from "../../components/FilterDate";
import { FilterSelect } from "../../components/FilterSelect";
import { useState } from "react";
import { isArray, set } from 'lodash'
import { useGetFilterCompanies } from "../../hooks/useGetFilterCompanies";
import { useGetFilterJobs } from "../../hooks/useGetFilterJobs";
import { RequestsReportParams, useGetRequestsReport } from "../../hooks/useGetRequestsReport";
import { useGetFilterStores } from "../../hooks/useGetFilterStores";

export function RequestsReport() {
  const [searchParams] = useSearchParams()
  const page = parseInt(searchParams.get('page') || '1')
  const date = searchParams.getAll('date') || undefined
  const createdAt = searchParams.get('createdAt') || undefined
  const companyId = searchParams.get('companyId') || undefined
  const storeId = searchParams.get('storeId') || undefined
  const jobId = searchParams.get('jobId') || undefined
  const { requestsReportData, isRequestsReportLoading } = useGetRequestsReport({
    page,
    date,
    createdAt,
    companyId,
    storeId,
    jobId,
  })

  return (
    <Wrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Dashboard
            </Link>
            <Typography color="text.primary">Relatório</Typography>
            <Typography color="text.primary">Vagas</Typography>
          </Breadcrumbs>
        </Grid>

        <RequestsReportFilter
          loading={isRequestsReportLoading}
          page={page}
          date={date}
          createdAt={createdAt}
          companyId={companyId}
          storeId={storeId}
          jobId={jobId}
        />

        <PaperTable
          title="Relatório"
          loading={isRequestsReportLoading}
          headers={[
            'Ref', 
            'Empresa', 
            'Loja', 
            'Função', 
            'Vagas Restantes', 
            'Total de Vagas', 
            'Data de Início', 
            'Data de Lançamento',
          ]}
          columns={[
            { 
              key: 'id',
              render: (val) => `#${val}`,
            },
            { 
              key: 'company', 
              render: (_, { store: { company: { name } } }) => name,
            },
            { 
              key: 'store',
              render: (_, { store: { name }}) => name,
            },
            { 
              key: 'job',
              render: (_, { job: { name }}) => name,
            },
            { key: 'amount' },
            { key: 'initial_amount' },
            { key: 'start_at' },
            { key: 'finish_at' },
          ]}
          data={requestsReportData?.list.requests ?? []}
          pagination={requestsReportData?.list ? {
            currentPage: requestsReportData.list.currentPage,
            totalPages: requestsReportData.list.lastPage,
            totalResults: requestsReportData.list.totalResults,
          } : undefined}
        />
      </Grid>
    </Wrapper>
  )
}

type RequestsReportFilterProps = {
  loading: boolean
} & RequestsReportParams

function RequestsReportFilter(props: RequestsReportFilterProps) {
  const [filter, setFilter] = useState<RequestsReportParams>({
    page: props.page,
    date: props.date,
    createdAt: props.createdAt,
    companyId: props.companyId,
    storeId: props.jobId,
    jobId: props.jobId,
  })
  const [, setSearchParams] = useSearchParams()
  const { companiesData, isCompaniesLoading } = useGetFilterCompanies()
  const { storesData, isStoresLoading } = useGetFilterStores({ 
    companyId: filter.companyId,
  })
  const { jobsData, isJobsLoading } = useGetFilterJobs({
    storeId: filter.storeId,
  })

  const [startAt, finishAt] = getStartAndFinishAt(filter.date)

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>Buscar</Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FilterDate
              label="Início em"
              onChange={(selectedStartAt) => {
                if (selectedStartAt && finishAt) {
                  setFilter(currFilter => ({
                    ...currFilter,
                    date: [selectedStartAt, finishAt],
                  }))
                } else if (selectedStartAt &&  !finishAt) {
                  setFilter(currFilter => ({
                    ...currFilter,
                    date: selectedStartAt,
                  }))
                } else {
                  setFilter(currFilter => ({
                    ...currFilter,
                    date: undefined,
                  }))
                }
              }}
              disabled={props.loading}
              value={startAt ?? ''}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FilterDate
              label="Término em"
              onChange={(selectedFinishAt) => {
                if (selectedFinishAt && startAt) {
                  setFilter(currFilter => ({
                    ...currFilter,
                    date: [startAt, selectedFinishAt],
                  }))
                } else if (selectedFinishAt &&  !startAt) {
                  throw new Error('Data inválida')
                } else if (!selectedFinishAt && startAt) {
                  setFilter(currFilter => ({
                    ...currFilter,
                    date: startAt,
                  }))
                } else {
                  setFilter(currFilter => ({
                    ...currFilter,
                    date: undefined,
                  }))
                }

                setFilter(currFilter => ({
                  ...currFilter,
                  finishAt,
                }))
              }}
              disabled={props.loading || !startAt}
              value={finishAt ?? ''}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FilterDate
              label="Lançamento em"
              onChange={(createdAt) => {
                setFilter(currFilter => ({
                  ...currFilter,
                  createdAt,
                }))
              }}
              disabled={props.loading}
              value={filter?.createdAt ?? ''}
            />
          </Grid>
          {companiesData ? (
            <Grid item xs={12} md={6} lg={4}>
              <FilterSelect
                label="Empresas"
                data={companiesData.map(company => ({
                  id: company.id,
                  key: company.id,
                  value: company.value,
                }))}
                disabled={props.loading || isCompaniesLoading}
                onChange={(companyId) => {
                  setFilter(currFilter => ({
                    ...currFilter,
                    companyId,
                  }))
                }}
                value={filter?.companyId ?? ''}
              />
            </Grid>
          ) : null}
          {storesData?.length ? (
            <Grid item xs={12} md={6} lg={4}>
              <FilterSelect
                label="Lojas"
                data={storesData.map(store => ({
                  id: store.id,
                  key: store.id,
                  value: store.value,
                }))}
                disabled={props.loading || isStoresLoading}
                onChange={(storeId) => {
                  setFilter(currFilter => ({
                    ...currFilter,
                    storeId,
                  }))
                }}
                value={filter?.storeId ?? ''}
              />
            </Grid>
          ) : null}
          {jobsData ? (
            <Grid item xs={12} md={6} lg={4}>
              <FilterSelect
                label="Cargos"
                data={jobsData.map(job => ({
                  id: job.id,
                  key: job.id,
                  value: job.name
                }))}
                disabled={props.loading || isJobsLoading}
                onChange={(jobId) => {
                  setFilter(currFilter => ({
                    ...currFilter,
                    jobId,
                  }))
                }}
                value={filter?.jobId ?? ''}
              />
            </Grid>
          ) : null}
          
          <Grid item xs={12}>
            <CustomButton
              onClick={() => {
                if (filter) {
                  const params = {
                    page: props.page.toString(),
                  }

                  if (startAt && finishAt) {
                    set(params, 'date', [startAt, finishAt])
                  } else if (startAt && !finishAt) {
                    set(params, 'date', startAt)
                  } else if (!startAt && finishAt) {
                    set(params, 'date', finishAt)
                  }

                  if (filter.createdAt) {
                    set(params, 'createdAt', filter.createdAt)
                  }

                  if (filter.companyId) {
                    set(params, 'companyId', filter.companyId)
                  }

                  if (filter.storeId) {
                    set(params, 'storeId', filter.storeId)
                  }

                  if (filter.jobId) {
                    set(params, 'jobId', filter.jobId)
                  }

                  setSearchParams(params)
                }
              }}
            >
              Buscar
            </CustomButton>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

function getStartAndFinishAt(date?: string | string[]) {
  if (!date) {
    return [undefined, undefined]
  }

  if (isArray(date)) {
    if (date.length !== 2) {
      return [undefined, undefined]
    }

    return date
  }

  return [date, undefined]
}