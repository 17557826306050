import { gql, useMutation } from "@apollo/client";

type AddInstitutionUnitQuery = {
  institution_id: number
  name: string
  cnpj: string
  street: string
  complement?: string
  number?: number
  neighborhood: string
  city: string
  state_id: number
  cep: string
  reference?: string
  lat: number
  long: number
  image?: string
}

const addInstitutionUnitQuery  = gql`
  mutation InsertInstitutionUnit(
    $institution_id: ID!
    $name: String!
    $cnpj: String!
    $street: String!
    $complement: String
    $number: Int
    $neighborhood: String!
    $city: String!
    $cep: String!
    $reference: String
    $lat: Float!
    $long: Float!
    $image: Upload
    $state_id: ID!
  ) {
    insertInstitutionUnit(
      institution_id: $institution_id
      name: $name
      cnpj: $cnpj
      street: $street
      complement: $complement
      number: $number
      neighborhood: $neighborhood
      city: $city
      cep: $cep
      reference: $reference
      lat: $lat
      long: $long
      image: $image
      state_id: $state_id
    ) {
      id
      name
      cnpj
      street
      complement
      number
      neighborhood
      city
      state {
        id
        name
      }
      cep
      reference
      lat
      long
      image
      created_at
      updated_at
    }
  }
`

export function useAddInstitutionUnit() {
  const [addInstitutionUnit, { data, loading, error }] = useMutation<any, AddInstitutionUnitQuery>(addInstitutionUnitQuery)

  return {
    institutionUnitsData: data,
    isInstitutionUnitsLoading: loading,
    institutionUntisError: error,
    addInstitutionUnit,
  }
}