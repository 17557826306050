import { Navigate, Outlet, useLocation } from "react-router-dom"
import { AuthStorage } from "../../utils/auth/AuthStorage"

type ProtectedRouteProps = {
  isLoginRoute?: boolean
}

export function ProtectedRoute(props: ProtectedRouteProps) {
  const location = useLocation()
  const isLoggedIn = AuthStorage.getAccessToken()

  if (isLoggedIn) {
    if (props.isLoginRoute) {
      return <Navigate to="/" />
    }

    return <Outlet />
  }

  if (props.isLoginRoute) {
    return <Outlet />
  }

  return <Navigate to="/login" state={{ from: location.pathname }} />
}