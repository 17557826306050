import { gql, useQuery } from "@apollo/client";

type RolesResponse = {
  list: Array<{
    id: string
    name: string
  }>
}

const rolesQuery = gql`
  {
    list: roles {
      id
      name
    }
  }
`

export function useGetRoles() {
  const { data, loading, error } = useQuery<RolesResponse>(rolesQuery)

  return {
    rolesData: data,
    isRolesLoading: loading,
    rolesError: error,
  }
}