import { gql, useLazyQuery } from "@apollo/client";

type LoginQuery = {
  login: string
  password: string
}

type LoginResponse = {
  access_token: string
}

const loginQuery = gql`
  query loginAdmin($login: String!, $password: String!) {
    access_token: loginAdministrator(login: $login, password: $password)
  }
`

export function useLogin() {
  const [login, { loading }] = useLazyQuery<LoginResponse, LoginQuery>(loginQuery)

  return {
    login,
    isLoginLoading: loading,
  }
}