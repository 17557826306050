import { gql, useQuery } from "@apollo/client";

type InstitutionsParams = {
  page: number
}

type InstitutionsQuery = InstitutionsParams

type InstitutionsResponse = {
  list: {
    currentPage: number
    firstPage: number
    lastPage: number
    nextPage: number
    prevPage: number
    totalResults: number
    institutions: Array<{
      id: string
      cnpj: string
      name: string
      status: boolean
    }>
  }
}

const institutionsQuery = gql`
  query Institutions($page: Int) {
    list: institutions(page: $page) {
      firstPage
      lastPage
      currentPage
      nextPage
      prevPage
      totalResults
      institutions {
        id
        name
        cnpj
        status
      }
    }
  }
`

export function useGetInstitutions(props: InstitutionsParams) {
  const { data, loading, error } = useQuery<InstitutionsResponse, InstitutionsQuery>(institutionsQuery, {
    variables: {
      page: props.page,
    }
  })

  return {
    institutionsData: data,
    isInstitutionsLoading: loading,
    institutionsError: error,
  }
}