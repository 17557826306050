import { Breadcrumbs, Grid, Link, Paper, Typography } from "@mui/material";
import { Wrapper } from "../../components/Wrapper";
import { useForm } from "react-hook-form";
import { Form } from "../../components/Form";
import { ConfirmButton } from "../../components/ConfirmButton";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { CustomButton } from "../../components/CustomButton";
import { InputField } from "../../components/InputField";
import { ApolloError, useApolloClient } from "@apollo/client";
import { useGetInstitution } from "../../hooks/useGetInstitution";
import { LoadingPage } from "../LoadingPage";
import { useAddInstitutionUnit } from "../../hooks/useAddInstitutionUnit";
import { useGetStates } from "../../hooks/useGetStates";
import { FilterSelect } from "../../components/FilterSelect";
import { useState } from "react";

type InstitutionUnitAddForm = {
  name: string
  cnpj: string
  address1: string
  address2: string
  number: string
  neighborhood: string
  city: string
  state: string
  zipcode: string
  reference: string
  lat: string
  lng: string
  image: string
}

type InstitutionUnitAddParams = {
  institutionId: string
}

export function InstitutionUnitAddPage() {
  const { institutionId } = useParams<InstitutionUnitAddParams>()
  const { institutionData, isInstitutionLoading } = useGetInstitution({ id: institutionId ? parseInt(institutionId) : undefined })

  if (!institutionId) {
    return <Navigate to="/institutions" replace />
  }

  if (isInstitutionLoading) {
    return <LoadingPage />
  }

  return (
    <Wrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Dashboard
            </Link>
            <Link underline="hover" color="inherit" href="/institutions">
              Instituições
            </Link>
            <Link underline="hover" color="inherit" href={`/institutions/${institutionId}/units`}>
              Unidades{institutionData ? ` - ${institutionData.item.name} #${institutionData.item.id}` : ''}
            </Link>
            <Typography color="text.primary">Adicionar Unidade</Typography>
          </Breadcrumbs>
        </Grid>
        
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <InstitutionUnitForm institutionId={institutionId} />
          </Paper>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

type InstitutionUnitFormProps = {
  institutionId: string
}

function InstitutionUnitForm(props: InstitutionUnitFormProps) {
  const client = useApolloClient()
  const navigate = useNavigate()
  const [state, setState] = useState('')
  const { addInstitutionUnit, isInstitutionUnitsLoading } = useAddInstitutionUnit()
  const { statesData, isStatesLoading } = useGetStates()

  const { handleSubmit, control } = useForm<InstitutionUnitAddForm>({
    defaultValues: {
      name: '',
      cnpj: '',
      address1: '',
      address2: '',
      number: '',
      neighborhood: '',
      city: '',
      state: '',
      zipcode: '',
      reference: '',
      lat: '',
      lng: '',
      image: '',
    }
  })

  return (
    <Form
      control={control}
      onSubmit={handleSubmit((data) => {
        addInstitutionUnit({
          variables: {
            institution_id: parseInt(props.institutionId),
            name: data.name,
            cnpj: data.cnpj,
            street: data.address1,
            complement: data.address2,
            number: data.number ? parseInt(data.number) : undefined,
            neighborhood: data.neighborhood,
            city: data.city,
            state_id: parseInt(state),
            cep: data.zipcode,
            reference: data.reference,
            lat: parseFloat(data.lat),
            long: parseFloat(data.lng),
            image: data.image,
          }, 
          onCompleted: async () => {
            await client.refetchQueries({
              include: "all",
            })
            alert('Unidade salva com sucesso!')
            navigate(`/institutions/${props.institutionId}/units`)
          },
          onError: (err: ApolloError) => {
            // TODO: Show toast on error?
            alert(err.message)
          },
        })
      })}
      sx={{ mt: 1 }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <InputField
            name="name"
            rules={{ required: true }}
            label="Nome"
            autoFocus
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputField
            name="cnpj"
            rules={{ required: true }}
            label="CNPJ"
            format="cnpj"
          />
        </Grid>
      </Grid>
      <InputField
        name="address1"
        rules={{ required: true }}
        label="Rua"
      />
      <InputField
        name="address2"
        label="Complemento"
      />
      <InputField
        name="number"
        label="Número"
      />
      <InputField
        name="neighborhood"
        rules={{ required: true }}
        label="Bairro"
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <InputField
            name="city"
            rules={{ required: true }}
            label="Cidade"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FilterSelect
            label="Estado"
            data={statesData?.states.map(state => ({
              id: state.id,
              key: state.id,
              value: state.name,
            })) ?? []}
            loading={isStatesLoading}
            disabled={isInstitutionUnitsLoading}
            onChange={setState}
            value={state}
          />
        </Grid>
      </Grid>
      <InputField
        name="zipcode"
        rules={{ required: true }}
        label="CEP"
      />
      <InputField
        name="reference"
        label="Referência"
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <InputField
            name="lat"
            rules={{ required: true }}
            label="Latitutde"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            name="lng"
            rules={{ required: true }}
            label="Longitude"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ConfirmButton
            loading={isInstitutionUnitsLoading}
          >
            Salvar
          </ConfirmButton>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomButton
            color="error"
            disabled={isInstitutionUnitsLoading}
            onClick={() => navigate(`/institutions/${props.institutionId}/units`)}
          >
            Cancelar
          </CustomButton>
        </Grid>
      </Grid>
    </Form>
  )
}