import { gql, useMutation } from "@apollo/client";

type AddInstitutionQuery = {
  name: string
  cnpj: string
  username: string
  password: string
}

const addInstitutionQuery  = gql`
  mutation InsertInstitution(
    $name: String!
    $cnpj: String!
    $username: String!
    $password: String!
  ) {
    insertInstitution(
      name: $name
      cnpj: $cnpj
      username: $username
      password: $password
    ) {
      id
      name
    }
  }
`

export function useAddInstitution() {
  const [addInstitution, { data, loading, error }] = useMutation<any, AddInstitutionQuery>(addInstitutionQuery)

  return {
    institutionsData: data,
    isInstitutionsLoading: loading,
    institutionsError: error,
    addInstitution,
  }
}