import { Box, BoxProps } from "@mui/material";
import { PropsWithChildren, createContext } from "react";

type FormContextProps = {
  control?: any
}

type FormProps = PropsWithChildren & Omit<BoxProps, 'component'> & FormContextProps

export const FormContext = createContext<FormContextProps>({})

export function Form({ children, control, ...props }: FormProps) {
  return (
    <FormContext.Provider value={{ control }}>
      <Box component="form" {...props}>
        {children}
      </Box>
    </FormContext.Provider>
  )
}