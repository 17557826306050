import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";
import { LocalStorage } from "../utils/storage/LocalStorage";
import { StorageKeys } from "../utils/auth/AuthStorage";

const graphQlUrl = process.env.REACT_APP_GRAPHQL

const httpLink = new HttpLink({
  uri: graphQlUrl
})

const authLink = new ApolloLink((operation, forward) => {
  try {
    const accessToken = LocalStorage.getItem(StorageKeys.AccessToken)
  
    if (accessToken) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    }
  } catch(e) {}

  return forward(operation)
})

export const graphQlClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})
