import { Breadcrumbs, Grid, Link, Paper, Typography } from "@mui/material";
import { Wrapper } from "../../components/Wrapper";
import { useForm } from "react-hook-form";
import { Form } from "../../components/Form";
import { ConfirmButton } from "../../components/ConfirmButton";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { CustomButton } from "../../components/CustomButton";
import { InputField } from "../../components/InputField";
import { ApolloError, useApolloClient } from "@apollo/client";
import { useGetInstitution } from "../../hooks/useGetInstitution";
import { EditInstitutionQuery, useEditInstitution } from "../../hooks/useEditInstitution";
import { LoadingPage } from "../LoadingPage";

type InstitutionEditParams = {
  institutionId: string
}

export function InstitutionEditPage() {
  const { institutionId } = useParams<InstitutionEditParams>()
  const { institutionData, isInstitutionLoading } = useGetInstitution({ id: institutionId ? parseInt(institutionId) : undefined })

  if (!institutionId) {
    return <Navigate to="/institutions" replace />
  }

  if (isInstitutionLoading) {
    return <LoadingPage />
  }

  if (!institutionData) {
    return <Navigate to="/institutions" replace />
  }

  return (
    <Wrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Dashboard
            </Link>
            <Link underline="hover" color="inherit" href="/institutions">
              Instituições
            </Link>
            <Typography color="text.primary">Editar Instituição{institutionData ? ` - ${institutionData.item.name} #${institutionData.item.id}` : ''}</Typography>
          </Breadcrumbs>
        </Grid>
        
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <InstitutionForm 
              institutionId={institutionId}
              institution={{
                cnpj: institutionData?.item.cnpj ?? '',
                email: institutionData?.item.username ?? '',
                name: institutionData?.item.name ?? '',
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

type InstitutionEditForm = {
  name: string
  cnpj: string
  email: string
  password: string
  passwordConfirmation: string
}

type InstitutionFormProps = {
  institutionId: string
  institution: Omit<InstitutionEditForm, 'password' | 'passwordConfirmation'>
}

function InstitutionForm(props: InstitutionFormProps) {
  const client = useApolloClient()
  const navigate = useNavigate()
  const { editInstitution, isInstitutionLoading } = useEditInstitution()
  const { handleSubmit, control } = useForm<InstitutionEditForm>({
    defaultValues: {
      cnpj: props.institution.cnpj,
      email: props.institution.email,
      name: props.institution.name,
      password: '',
      passwordConfirmation: '',
    }
  })

  return (
    <Form
      control={control}
      onSubmit={handleSubmit((data) => {
        if (data.password !== data.passwordConfirmation) {
          alert('A Senha e a Confirmação de Senha são inválidas')
        } else {
          const variables: EditInstitutionQuery = {
            id: props.institutionId,
            name: data.name,
            cnpj: data.cnpj,
            username: data.email,
          }

          if (data.password) {
            variables.password = data.password
          }

          editInstitution({
            variables, 
            onCompleted: async () => {
              await client.refetchQueries({
                include: "all",
              })
              alert('Instituição salva com sucesso!')
              navigate('/institutions')
            },
            onError: (err: ApolloError) => {
              // TODO: Show toast on error?
              alert(err.message)
            },
          })
        }
      })}
      sx={{ mt: 1 }}
    >
      <InputField
        name="name"
        rules={{ required: true }}
        label="Nome"
        autoFocus
      />
      <InputField
        name="cnpj"
        rules={{ required: true }}
        label="CNPJ"
        format="cnpj"
      />
      <InputField
        name="email"
        rules={{ required: true }}
        label="Email"
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <InputField 
            name="password"
            label="Senha"
            type="password"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField 
            name="passwordConfirmation"
            rules={{ 
              validate: (value, { password }) => {
                if (password && value !== password) {
                  return 'A Senha e a Confirmação de Senha não conferem'
                }

                return true
              }
            }}
            label="Confirmação de Senha"
            type="password"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <ConfirmButton
            loading={isInstitutionLoading}
          >
            Salvar
          </ConfirmButton>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomButton
            color="error"
            disabled={isInstitutionLoading}
            onClick={() => navigate('/institutions')}
          >
            Cancelar
          </CustomButton>
        </Grid>
      </Grid>
    </Form>
  )
}

