import { Grid, Paper } from "@mui/material";
import { Wrapper } from "../../components/Wrapper";
import { useGetDashboardReports } from "../../hooks/useGetDashboardReports";
import { CalendarMonth, Cancel, ContentPasteOff, HourglassBottom, People, PersonAdd, PersonOff, Watch } from "@mui/icons-material";
import { useState } from "react";
import { useGetFilterCompanies } from "../../hooks/useGetFilterCompanies";
import { DashboardReportCard } from "./DashboardReportCard";
import { useGetFilterStores } from "../../hooks/useGetFilterStores";
import { FilterSelect } from "../../components/FilterSelect";
import { FilterDate } from "../../components/FilterDate";

export function DashboardPage() {
  const [company, setCompany] = useState<string>('')
  const [store, setStore] = useState<string>('')
  const [startAt, setStartAt] = useState<string>('')
  const [finishAt, setFinishAt] = useState<string>('')
  const { companiesData, isCompaniesLoading } = useGetFilterCompanies()
  const { storesData, isStoresLoading } = useGetFilterStores({ companyId: company })
  const { dashboardData, isDashboardLoading } = useGetDashboardReports({
    companyId: company,
    storeId: store,
    startAt,
    finishAt,
  })

  return (
    <Wrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <FilterSelect
                  label="Empresa"
                  data={companiesData}
                  loading={isCompaniesLoading}
                  disabled={isDashboardLoading}
                  onChange={setCompany}
                  value={company}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FilterSelect
                  label="Lojas"
                  data={storesData}
                  loading={isCompaniesLoading || isStoresLoading}
                  disabled={isDashboardLoading}
                  onChange={setStore}
                  value={store}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FilterDate
                  label="Início em"
                  onChange={setStartAt}
                  disabled={isDashboardLoading}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FilterDate 
                  label="Término em"
                  onChange={setFinishAt}
                  disabled={isDashboardLoading}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <DashboardReportCard
          title="Horas Consumidas"
          loading={isDashboardLoading}
          total={dashboardData?.hoursConsumed}
          icon={<HourglassBottom />}
        />
        <DashboardReportCard
          title="Média de Horas"
          loading={isDashboardLoading}
          total={dashboardData?.averageHours}
          icon={<Watch />}
        />
        <DashboardReportCard
          title="Taxa de Cancelamento"
          loading={isDashboardLoading}
          total={`${dashboardData?.cancellationRate}%`}
          icon={<Cancel />}
        />
        <DashboardReportCard
          title="Taxa de Ocupação"
          loading={isDashboardLoading}
          total={`${dashboardData?.occupancyRate}%`}
          icon={<ContentPasteOff />}
        />
        <DashboardReportCard
          title="Taxa de Falta"
          loading={isDashboardLoading}
          total={`${dashboardData?.missRate}%`}
          icon={<ContentPasteOff />}
        />
        <DashboardReportCard
          title="Total de Agendamentos"
          loading={isDashboardLoading}
          total={dashboardData?.totalAppointments}
          icon={<CalendarMonth />}
        />
        <DashboardReportCard
          title="Total de Vagas"
          loading={isDashboardLoading}
          total={dashboardData?.totalVacancies}
          icon={<People />}
        />
        <DashboardReportCard
          title="Usuários Ativos"
          loading={isDashboardLoading}
          total={dashboardData?.totalActiveWorkers}
          icon={<PersonAdd />}
        />
        <DashboardReportCard
          title="Usuários inativos"
          loading={isDashboardLoading}
          total={dashboardData?.totalInactiveWorkers}
          icon={<PersonOff />}
        />
      </Grid>
    </Wrapper>
  )
}
