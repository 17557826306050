import { gql, useQuery } from "@apollo/client";

type InstitutionUnitsParams = {
  id?: string
  page?: number
}

type InstitutionUnitsQuery = InstitutionUnitsParams

type InstitutionUnitsResponse = {
  list: {
    currentPage: number
    firstPage: number
    lastPage: number
    nextPage: number
    prevPage: number
    totalResults: number
    institutionUnits: Array<{
      id: string
      cnpj: string
      name: string
      status: boolean
    }>
  }
}

const institutionUnitsQuery = gql`
  query InstitutionUnits($page: Int, $id: ID!) {
    list: institutionUnits(page: $page, institution_id: $id) {
      firstPage
      lastPage
      currentPage
      nextPage
      prevPage
      totalResults
      institutionUnits {
        id
        name
        cnpj
        street
        neighborhood
        city
        state {
          name
        }
      }
    }
  }
`

export function useGetInstitutionUnits(props: InstitutionUnitsParams) {
  const { data, loading, error } = useQuery<InstitutionUnitsResponse, InstitutionUnitsQuery>(institutionUnitsQuery, {
    variables: {
      id: props.id,
      page: props.page,
    },
    skip: !props.id,
  })

  return {
    institutionUnitsData: data,
    isInstitutionUnitsLoading: loading,
    institutionUnitsError: error,
  }
}