import { Button, ButtonProps } from "@mui/material";
import { PropsWithChildren } from "react";

type CustomButtonProps = Omit<ButtonProps, 'fullWidth' | 'variant' | 'sx'>

export function CustomButton({ children, ...props }: PropsWithChildren<CustomButtonProps>) {
  return (
    <Button
      fullWidth
      variant="contained"
      sx={{ mt: 3, mb: 2 }}
      {...props}
    >
      {children}
    </Button>
  )
}