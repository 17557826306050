import { TextField, TextFieldProps } from "@mui/material";
import { forwardRef, useContext } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { FormContext } from "../Form";
import { InputMask, InputMaskProps } from "@react-input/mask";

type InputFieldProps = Omit<TextFieldProps, 'name'> & {
  name: string
  rules?: ControllerProps['rules']
  format?: 'phone' | 'cnpj'
}

export function InputField({ margin = 'normal', fullWidth = true, format, ...props }: InputFieldProps) {
  const { control } = useContext(FormContext)

  if (!control) {
    throw new Error('Control wasn\'t provided')
  }

  return (
    <Controller
      name={props.name}
      control={control}
      rules={props.rules ?? {}}
      render={({ field, fieldState }) => {
        const isError = Boolean(fieldState.error)

        return (
          <TextField 
            {...props}
            {...field}
            margin={margin}
            fullWidth={fullWidth}
            autoComplete={props.name}
            error={isError}
            InputProps={getInputProps({ format })}
            helperText={
              isError 
                ? (
                  fieldState.error?.message ?? 'Verifique se o campo foi preenchido corretamente' 
                ) : null
              }
          />
        )
      }}
    />
  )
}

function getInputProps(params: Pick<InputFieldProps, 'format'>) {
  switch (params.format) {
    case 'cnpj':
      return {
        inputComponent: CnpjInputMask,
      }
    case 'phone':
      return {
        inputComponent: PhoneInputMask,
      }
  }

  return undefined
}

const PhoneInputMask = forwardRef<HTMLInputElement, InputMaskProps>((props, forwardedRef) => {
  return <InputMask ref={forwardedRef} mask="(__) _____-____" replacement={{ _: /\d/ }} {...props} />
})

const CnpjInputMask = forwardRef<HTMLInputElement, InputMaskProps>((props, forwardedRef) => {
  return <InputMask ref={forwardedRef} mask="__.___.___/____-__" replacement={{ _: /\d/ }} {...props} />
})
