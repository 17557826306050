import { FormControl } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

type FilterDateProps = {
  label: string
  disabled?: boolean
  onChange: (value: string) => void
  value?: string
}

export function FilterDate(props: FilterDateProps) {
  const value = props.value ? dayjs(props.value) : undefined

  return (
    <FormControl fullWidth margin="normal">
      <DatePicker
        format="DD/MM/YYYY"
        label={props.label}
        onChange={(newVal) => {
          props.onChange(newVal ? newVal.format('YYYY-MM-DD') : '')
        }}
        disabled={props.disabled}
        defaultValue={value}
      />
    </FormControl>
  )
}