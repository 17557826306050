import { gql, useMutation } from "@apollo/client";

type DeleteInstitutionUnitQuery = {
  id: string
}

const deleteInstitutionUnitQuery  = gql`
  mutation DeleteInstitutionUnit($id: ID!) {
    deleteInstitutionUnit(id: $id)
  }
`

export function useDeleteInstitutionUnit() {
  const [deleteInstitutionUnit, { loading }] = useMutation<any, DeleteInstitutionUnitQuery>(deleteInstitutionUnitQuery)

  return {
    isDeleteInstitutionUnitsLoading: loading,
    deleteInstitutionUnit,
  }
}