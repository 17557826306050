import { jwtDecode } from "jwt-decode"
import { LocalStorage } from "../storage/LocalStorage"

type AuthCredentialsResponse = {
  access_token: string
}

export enum StorageKeys {
  AccessToken = 'accessToken',
}

export class AuthStorage {
  static setAuthCredentials({ access_token }: AuthCredentialsResponse) {
    AuthStorage.setAccessToken(access_token)
  }

  static setAccessToken(token: string) {
    LocalStorage.setItem(StorageKeys.AccessToken, token)
  }

  static getAccessToken() {
    try {
      const item = LocalStorage.getItem(StorageKeys.AccessToken)
      return item
    } catch (err) {
      return ''
    }
  }

  static getUser = () => {
    const token = this.getAccessToken()
    const decodedToken = jwtDecode<{
      user: {
        id: string
        login: string
        role: string
        state: string
      }
    }>(token)

    return decodedToken.user
  }
}