import { useState } from "react";
import { ProgramsReportParams } from "../../hooks/useGetProgramsReport";
import { useGetProgramsReportExport } from "../../hooks/useGetProgramsReportExport";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { Download, MoreVert } from "@mui/icons-material";
import { ApolloError } from "@apollo/client";
import downloadjs from 'downloadjs'

type ProgramsReportExportProps = {
  loading: boolean
} & ProgramsReportParams['filter']

export function ProgramsReportExport(props: ProgramsReportExportProps) {
  const longButtonId = crypto.randomUUID()
  const longMenuId = crypto.randomUUID()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { exportProgramsReport, isProgramsReportExportLoading } = useGetProgramsReportExport()

  const handleDownload = (format: 'PDF' | 'XLSX') => {
    exportProgramsReport({
      variables: {
        format: format,
        filter: {
          startAt: props.startAt,
          finishAt: props.finishAt,
          type: props.type,
          training_id: props.training_id,
          certificate_id: props.certificate_id,
          job_id: props.job_id,
        },
      },
      onCompleted: (response) => {
        console.log('response', response)

        const mimeType = `application/${
          format === 'PDF' ? 'pdf' : 'vnd.ms-excel'
        }`
        downloadjs(
          ['data:', mimeType, ';base64,', response.result].join(''),
          `cursos.${format === 'PDF' ? 'pdf' : 'xlsx'}`,
          mimeType
        )
      },
      onError: (err: ApolloError) => {
        alert(err.message)
      }
    })
  }

  return (
    <>
      <IconButton
        id={longButtonId}
        aria-label="Opções"
        aria-controls={open ? longMenuId : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id={longMenuId}
        MenuListProps={{
          'aria-labelledby': longButtonId,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          disabled={props.loading || isProgramsReportExportLoading}
          onClick={() => {
            setAnchorEl(null)
            handleDownload('PDF')
          }}
        >
          <ListItemIcon>
            <Download />
          </ListItemIcon>
          <ListItemText>Exportar PDF</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={props.loading || isProgramsReportExportLoading}
          onClick={() => {
            setAnchorEl(null)
            handleDownload('XLSX')
          }}
        >
          <ListItemIcon>
            <Download />
          </ListItemIcon>
          <ListItemText>Exportar Excel</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}