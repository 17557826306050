import { Breadcrumbs, Grid, Link, Paper, Typography } from "@mui/material";
import { Wrapper } from "../../components/Wrapper";
import { PaperTable } from "../../components/PaperTable";
import { ProgramsReportParams, ProgramsReportTypes, useGetProgramsReport } from "../../hooks/useGetProgramsReport";
import { useSearchParams } from "react-router-dom";
import { CustomButton } from "../../components/CustomButton";
import { FilterDate } from "../../components/FilterDate";
import { FilterSelect } from "../../components/FilterSelect";
import { useState } from "react";
import { useGetFilterTrainings } from "../../hooks/useGetFilterTrainings";
import { useGetFilterCertificates } from "../../hooks/useGetFilterCertificates";
import { set, unset } from 'lodash'
import { useGetFilterJobs } from "../../hooks/useGetFilterJobs";
import { ProgramsReportExport } from "./ProgramsReportExport";

export function ProgramsReport() {
  const [searchParams] = useSearchParams()
  const page = parseInt(searchParams.get('page') || '1')
  const startAt = searchParams.get('startAt') || undefined
  const finishAt = searchParams.get('finishAt') || undefined
  const type = searchParams.get('type') || undefined
  const trainingId = searchParams.get('training_id') || undefined
  const certificateId = searchParams.get('certificate_id') || undefined
  const jobId = searchParams.get('job_id') || undefined
  const { programsReportData, isProgramsReportLoading } = useGetProgramsReport({
    page,
    filter: {
      startAt,
      finishAt,
      type: type as ProgramsReportTypes,
      training_id: trainingId,
      certificate_id: certificateId,
      job_id: jobId,
    },
  })

  return (
    <Wrapper>
      <Grid container spacing={3}>
        <Grid item xs={10} md={9}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Dashboard
            </Link>
            <Typography color="text.primary">Relatório</Typography>
            <Typography color="text.primary">Cursos</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={2} md={3} display="flex" justifyContent="flex-end">
          <ProgramsReportExport 
            loading={isProgramsReportLoading}
            startAt={startAt}
            finishAt={finishAt}
            type={type as ProgramsReportTypes}
            training_id={trainingId}
            certificate_id={certificateId}
            job_id={jobId}
          />
        </Grid>

        <ProgramsReportFilter
          loading={isProgramsReportLoading}
          page={page}
          startAt={startAt}
          finishAt={finishAt}
          type={type as ProgramsReportTypes}
          training_id={trainingId}
          certificate_id={certificateId}
          job_id={jobId}
        />

        <PaperTable
          title="Relatório"
          loading={isProgramsReportLoading}
          headers={['Aprovados', 'Reprovados', 'Total', 'Data']}
          columns={[
            { key: 'approveds' },
            { key: 'notApproveds' },
            { key: 'quantity' },
            { key: 'date' },
          ]}
          data={programsReportData?.list.coursesReport ?? []}
          pagination={programsReportData?.list ? {
            currentPage: programsReportData.list.currentPage,
            totalPages: programsReportData.list.lastPage,
            totalResults: programsReportData.list.totalResults,
          } : undefined}
        />
      </Grid>
    </Wrapper>
  )
}

type ProgramsReportFilterProps = {
  loading: boolean
  page: number
} & ProgramsReportParams['filter']

function ProgramsReportFilter(props: ProgramsReportFilterProps) {
  const [filter, setFilter] = useState<ProgramsReportParams['filter']>({
    startAt: props.startAt,
    finishAt: props.finishAt,
    type: props.type,
    training_id: props.training_id,
    certificate_id: props.certificate_id,
    job_id: props.job_id,
  })
  const [, setSearchParams] = useSearchParams()
  const { trainingsData, isTrainingsLoading } = useGetFilterTrainings({ skip: filter?.type !== 'TREINAMENTO' })
  const { certificatesData, isCertificatesLoading } = useGetFilterCertificates({ skip: filter?.type !== 'CERTIFICAÇÃO' })
  const { jobsData, isJobsLoading } = useGetFilterJobs({ skip: filter?.type !== 'TRABALHO' })

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>Buscar</Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FilterDate
              label="Início em"
              onChange={(startAt) => {
                setFilter(currFilter => ({
                  ...currFilter,
                  startAt,
                }))
              }}
              disabled={props.loading}
              value={filter?.startAt ?? ''}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FilterDate
              label="Término em"
              onChange={(finishAt) => {
                setFilter(currFilter => ({
                  ...currFilter,
                  finishAt,
                }))
              }}
              disabled={props.loading}
              value={filter?.finishAt ?? ''}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FilterSelect
              label="Tipo"
              data={[
                { id: 'INTRODUTÓRIO', key: 'key-INTRODUTÓRIO', value: 'INTRODUTÓRIO' },
                { id: 'TREINAMENTO', key: 'key-TREINAMENTO', value: 'TREINAMENTO' },
                { id: 'CERTIFICAÇÃO', key: 'key-CERTIFICAÇÃO', value: 'CERTIFICAÇÃO' },
                { id: 'TRABALHO', key: 'key-TRABALHO', value: 'TRABALHO' },
              ]}
              disabled={props.loading}
              onChange={(type) => {
                setFilter(currFilter => ({
                  ...currFilter,
                  type,
                }))
              }}
              value={filter?.type ?? ''}
            />
          </Grid>
          {trainingsData ? (
            <Grid item xs={12} md={6} lg={4}>
              <FilterSelect
                label="Treinamento"
                data={trainingsData.map(training => ({
                  id: training.id,
                  key: training.id,
                  value: training.name
                }))}
                disabled={props.loading || isTrainingsLoading}
                onChange={(training_id) => {
                  setFilter(currFilter => {
                    if (currFilter) {
                      unset(currFilter, 'certificate_id')
                      unset(currFilter, 'job_id')
                    }

                    return {
                      ...currFilter,
                      training_id,
                    }
                  })
                }}
                value={filter?.training_id ?? ''}
              />
            </Grid>
          ) : null}
          {certificatesData ? (
            <Grid item xs={12} md={6} lg={4}>
              <FilterSelect
                label="Certificação"
                data={certificatesData.map(certificate => ({
                  id: certificate.id,
                  key: certificate.id,
                  value: certificate.name
                }))}
                disabled={props.loading || isCertificatesLoading}
                onChange={(certificate_id) => {
                  setFilter(currFilter => {
                    if (currFilter) {
                      unset(currFilter, 'training_id')
                      unset(currFilter, 'job_id')
                    }

                    return {
                      ...currFilter,
                      certificate_id,
                    }
                  })
                }}
                value={filter?.certificate_id ?? ''}
              />
            </Grid>
          ) : null}
          {jobsData ? (
            <Grid item xs={12} md={6} lg={4}>
              <FilterSelect
                label="Cargos"
                data={jobsData.map(job => ({
                  id: job.id,
                  key: job.id,
                  value: job.name
                }))}
                disabled={props.loading || isJobsLoading}
                onChange={(job_id) => {
                  setFilter(currFilter => {
                    if (currFilter) {
                      unset(currFilter, 'training_id')
                      unset(currFilter, 'certificate_id')
                    }

                    return {
                      ...currFilter,
                      job_id,
                    }
                  })
                }}
                value={filter?.job_id ?? ''}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <CustomButton
              onClick={() => {
                if (filter) {
                  const params = {
                    page: props.page.toString(),
                  }

                  if (filter.startAt) {
                    set(params, 'startAt', filter.startAt)
                  }

                  if (filter.finishAt) {
                    set(params, 'finishAt', filter.finishAt)
                  }

                  if (filter.type) {
                    set(params, 'type', filter.type)

                    if (filter.type === 'TREINAMENTO' && filter.training_id) {
                      set(params, 'training_id', filter.training_id)
                    }
                    if (filter.type === 'CERTIFICAÇÃO' && filter.certificate_id) {
                      set(params, 'certificate_id', filter.certificate_id)
                    }
                    if (filter.type === 'TRABALHO' && filter.job_id) {
                      set(params, 'job_id', filter.job_id)
                    }
                  }

                  setSearchParams(params)
                }
              }}
            >
              Buscar
            </CustomButton>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

