import { Avatar, Box, Container, Typography } from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { InputField } from "../../components/InputField";
import { Form } from "../../components/Form";
import { useLogin } from "../../hooks/useLogin";
import { ApolloError } from "@apollo/client";
import { AuthStorage } from "../../utils/auth/AuthStorage";
import { ConfirmButton } from "../../components/ConfirmButton";
import { useNavigate } from "react-router-dom";
import { Copyright } from "../../components/Copyright";

type LoginForm = {
  email: string
  password: string
}

export function LoginPage() {
  const navigate = useNavigate()
  const { isLoginLoading, login } = useLogin()
  const { handleSubmit, control } = useForm<LoginForm>({
    defaultValues: {
      email: '',
      password: '',
    }
  })

  return (
    <Container component="main" maxWidth="xs">
      <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Typography>
            Informe suas credenciais de acesso
          </Typography>
          <Form 
            control={control}
            onSubmit={handleSubmit((data) => {
              login({
                variables: {
                  login: data.email,
                  password: data.password
                },
                onCompleted: ({ access_token }) => {
                  AuthStorage.setAccessToken(access_token)
                  navigate('/', { replace: true })
                },
                onError: (err: ApolloError) => {
                  // TODO: Show toast on error?
                  alert(err.message)
                },
              })
            })} 
            sx={{ mt: 1 }}
          >
            <InputField 
              name="email"
              rules={{ required: true }}
              label="Email"
              autoFocus
            />
            <InputField 
              name="password"
              rules={{ required: true }}
              label="Password"
              type="password"
            />
            <ConfirmButton
              loading={isLoginLoading}
            >
              Entrar
            </ConfirmButton>
          </Form>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  )
}