export class LocalStorage {
  static setItem(key: string, value: string) {
    window.localStorage.setItem(key, value)
  }

  static getItem(key: string) {
    const item = window.localStorage.getItem(key)
    if (!item) {
      throw new Error('Invalid key.')
    }

    return item
  }

  static removeItem(key: string) {
    window.localStorage.removeItem(key)
  }

  static clear() {
    window.localStorage.clear()
  }
}