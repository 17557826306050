import { gql, useQuery } from "@apollo/client";

type DashboardReportsParams = {
  companyId?: string
  storeId?: string
  startAt?: string
  finishAt?: string
}

type DashboardReportsQuery = {
  worker_id: string | null,
  company_id?: string
  store_id?: string
  start_at?: string
  finish_at?: string
}

type DashboardReportsResponse = {
  item: {
    average_hours: number
    cancellation_rate: number
    hours_consumed: number
    miss_rate: number
    occupancy_rate: number
    total_active_workers: number
    total_appointments: number
    total_inactive_workers: number
    total_vacancies: number
  }
}

const dashboardQuery = gql`
   query dashboardAdminCards(
    $start_at: String
    $finish_at: String
    $store_id: ID
    $worker_id: ID
    $company_id: ID
  ) {
    item: dashboardAdminCards(
      start_at: $start_at
      finish_at: $finish_at
      store_id: $store_id
      worker_id: $worker_id
      company_id: $company_id
    ) {
      hours_consumed
      total_vacancies
      total_appointments
      occupancy_rate
      cancellation_rate
      average_hours
      total_active_workers
      total_inactive_workers
      miss_rate
    }
  }
`

export function useGetDashboardReports(params: DashboardReportsParams = {}) {
  const { data, loading, error } = useQuery<DashboardReportsResponse, DashboardReportsQuery>(dashboardQuery, {
    variables: {
      worker_id: null,
      company_id: params.companyId,
      store_id: params.storeId,
      start_at: params.startAt,
      finish_at: params.finishAt,
    }
  })

  return {
    dashboardData: data ? ParseDashboardReports(data) : data,
    isDashboardLoading: loading,
    dashboardError: error,
  }
}

type DashboardReports = {
  averageHours: number
  cancellationRate: number
  hoursConsumed: number
  missRate: number
  occupancyRate: number
  totalActiveWorkers: number
  totalAppointments: number
  totalInactiveWorkers: number
  totalVacancies: number
}

function ParseDashboardReports(data: DashboardReportsResponse): DashboardReports {
  return {
    averageHours: data?.item.average_hours,
    cancellationRate: data?.item.cancellation_rate,
    hoursConsumed: data?.item.hours_consumed,
    missRate: data?.item.miss_rate,
    occupancyRate: data?.item.occupancy_rate,
    totalActiveWorkers: data?.item.total_active_workers,
    totalAppointments: data?.item.total_appointments,
    totalInactiveWorkers: data?.item.total_inactive_workers,
    totalVacancies: data?.item.total_vacancies,
  }
} 