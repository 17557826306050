import { Box, Button, ButtonProps, CircularProgress } from "@mui/material";
import { PropsWithChildren } from "react";

type ConfirmButtonProps = PropsWithChildren & ButtonProps & {
  loading?: boolean
}

export function ConfirmButton({ children, loading, ...props}: ConfirmButtonProps) {
  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        {...props}
        disabled={loading ?? props.disabled}
      >
        {children}
      </Button>
      {loading ? (
        <CircularProgress
          size={24} 
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-8px',
            marginLeft: '-12px',
          }}
        />
      ) : null}
    </Box>
  )
}