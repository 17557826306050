import { gql, useQuery } from "@apollo/client";

type FilterStoresParams = {
  companyId?: string
}

type FilterStoresResponse = {
  elements: {
    items: Array<{
      id: string
      key: string
      value: string
      company: {
        name: string
      }
    }>
  }
}

type FilterStoresQuery = {
  companyId?: string
}

const filterStoresQuery = gql`
  query Stores($companyId: ID) {
    elements: stores(paginate: false, company_id: $companyId) {
      items: stores {
        id
        key: id
        value: name
        company {
          name
        }
      }
    }
  }
`

export function useGetFilterStores(params: FilterStoresParams) {
  const { data, loading, error } = useQuery<FilterStoresResponse, FilterStoresQuery>(filterStoresQuery, {
    skip: !params.companyId,
    variables: {
      companyId: params.companyId,
    }
  })

  return {
    storesData: data?.elements.items || [],
    isStoresLoading: loading,
    storesError: error,
  }
}