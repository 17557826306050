import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material"

type FilterProps = {
  label: string
  loading?: boolean
  disabled?: boolean
  data: Array<{
    id: string
    key: string
    value: string
  }>
  value: any
  onChange: (value: any) => void
}

export function FilterSelect(props: FilterProps) {
  const labelId = crypto.randomUUID()
  const id = crypto.randomUUID()

  return (
    <>
      {props.loading ? (
        <CircularProgress size={24} />
      ) : (
        <FormControl fullWidth margin="normal">
          <InputLabel id={labelId}>{props.label}</InputLabel>
          <Select
            labelId={labelId}
            id={id}
            label={props.label}
            fullWidth
            value={props.value}
            onChange={(e) => {
              props.onChange(e.target.value)
            }}
            disabled={props.disabled}
          >
            {props.data.map(data => (
              <MenuItem value={data.id} key={data.key}>{data.value}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  )
}