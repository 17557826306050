import { gql, useQuery } from "@apollo/client";

type FilterJobsParams = {
  skip?: boolean
  storeId?: string | number
}

type FilterJobsQuery = FilterJobsParams

type FilterJobsResponse = {
  elements: {
    jobs: Array<{
      id: string
      name: string
    }>
  }
}

const filterJobsQuery = gql`
  query Jobs($storeId: ID) {
    elements: jobs(
      paginate: false, 
      active: true,
      store_id: $storeId
    ) {
      jobs {
        id
        name
      }
    }
  }
`

export function useGetFilterJobs(params?: FilterJobsParams) {
  const { data, loading, error } = useQuery<FilterJobsResponse, FilterJobsQuery>(filterJobsQuery, {
    skip: params?.skip,
    variables: {
      storeId: params?.storeId,
    }
  })

  return {
    jobsData: data?.elements.jobs,
    isJobsLoading: loading,
    jobsError: error,
  }
}