import { Book, CalendarToday, ChevronLeft, Dashboard, DocumentScanner, House, LocalPostOffice, Logout, Money, NotificationImportant, People, RemoveCircle, School, Speaker, Work } from "@mui/icons-material";
import { Divider, Drawer, DrawerProps, IconButton, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Toolbar, styled } from "@mui/material";
import { LocalStorage } from "../../utils/storage/LocalStorage";
import { useNavigate } from "react-router-dom";
import { drawerWidth } from "../../config/theme";

type CustomDrawerProps = {
  open?: boolean
  toggleDrawer: (open: boolean) => void
}

export function CustomDrawer(props: CustomDrawerProps) {
  return (
    <StyledDrawer variant="permanent" open={props.open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton
          onClick={() => {
            props.toggleDrawer(!props.open)
          }}
        >
          <ChevronLeft />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        <MainMenuItems />
        <Divider sx={{ my: 1 }} />
        <ReportsMenuItems />
        <Divider sx={{ my: 1 }} />
        <StampsMenuItems />
        <Divider sx={{ my: 1 }} />
        <ConfigMenuItems />
      </List>
    </StyledDrawer>
  )
}

interface CustomStyledDrawer extends DrawerProps {
  open?: boolean
}

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})<CustomStyledDrawer>(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
)

const MainMenuItems = () => {
  const navigate = useNavigate()

  return (
    <>
      <ListItemButton onClick={() => navigate('/')}>
        <ListItemIcon>
          <Dashboard />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate('/institutions')}>
        <ListItemIcon>
          <House />
        </ListItemIcon>
        <ListItemText primary="Instituições" />
      </ListItemButton>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <House />
        </ListItemIcon>
        <ListItemText primary="Empresas" />
      </ListItemButton>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <People />
        </ListItemIcon>
        <ListItemText primary="Trabalhadores" />
      </ListItemButton>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <People />
        </ListItemIcon>
        <ListItemText primary="Ranking de Trabalhadores" />
      </ListItemButton>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <CalendarToday />
        </ListItemIcon>
        <ListItemText primary="Agendamentos" />
      </ListItemButton>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <Money />
        </ListItemIcon>
        <ListItemText primary="Solicitações de Saque" />
      </ListItemButton>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <NotificationImportant />
        </ListItemIcon>
        <ListItemText primary="Notificar" />
      </ListItemButton>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <Work />
        </ListItemIcon>
        <ListItemText primary="Campanhas" />
      </ListItemButton>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <Work />
        </ListItemIcon>
        <ListItemText primary="Vagas" />
      </ListItemButton>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <RemoveCircle />
        </ListItemIcon>
        <ListItemText primary="Exceçoes" />
      </ListItemButton>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <School />
        </ListItemIcon>
        <ListItemText primary="Cursos" />
      </ListItemButton>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <LocalPostOffice />
        </ListItemIcon>
        <ListItemText primary="Convites" />
      </ListItemButton>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <Speaker />
        </ListItemIcon>
        <ListItemText primary="Razões - Feedbacks" />
      </ListItemButton>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <Speaker />
        </ListItemIcon>
        <ListItemText primary="Feedback" />
      </ListItemButton>
    </>
  )
}

const ReportsMenuItems = () => {
  const navigate = useNavigate()

  return (
    <>
      <ListSubheader component="div" inset>
        Relatórios
      </ListSubheader>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <Money />
        </ListItemIcon>
        <ListItemText primary="Valores" />
      </ListItemButton>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <Work />
        </ListItemIcon>
        <ListItemText primary="Funções" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate('/requests/report')}>
        <ListItemIcon>
          <Work />
        </ListItemIcon>
        <ListItemText primary="Vagas não ocupadas" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate('/programs/report')}>
        <ListItemIcon>
          <School />
        </ListItemIcon>
        <ListItemText primary="Cursos" />
      </ListItemButton>
    </>
  )
}

const StampsMenuItems = () => {
  return (
    <>
      <ListSubheader component="div" inset>
        Selos
      </ListSubheader>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <DocumentScanner />
        </ListItemIcon>
        <ListItemText primary="Certificações" />
      </ListItemButton>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <Work />
        </ListItemIcon>
        <ListItemText primary="Funções" />
      </ListItemButton>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <Work />
        </ListItemIcon>
        <ListItemText primary="Treinamentos" />
      </ListItemButton>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <Work />
        </ListItemIcon>
        <ListItemText primary="Treinamentos Presenciais" />
      </ListItemButton>
    </>
  ) 
}

const ConfigMenuItems = () => {
  const navigate = useNavigate()

  return (
    <>
      <ListSubheader component="div" inset>
        Configurações Gerais
      </ListSubheader>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <People />
        </ListItemIcon>
        <ListItemText primary="Administradores" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate('/roles')}>
        <ListItemIcon>
          <People />
        </ListItemIcon>
        <ListItemText primary="Tipo de Usuário" />
      </ListItemButton>
      <ListItemButton onClick={() => alert('Em desenvolvimento. Aguarde!')}>
        <ListItemIcon>
          <Book />
        </ListItemIcon>
        <ListItemText primary="Termos" />
      </ListItemButton>
      <ListItemButton 
        onClick={() => {
          LocalStorage.clear()
          navigate('/login', { replace: true })
        }}
      >
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        <ListItemText primary="Sair" />
      </ListItemButton>
    </>
  )
}
