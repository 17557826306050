import { gql, useQuery } from "@apollo/client";

type InstitutionUnitParams = {
  id?: string
}

type InstitutionUnitQuery = InstitutionUnitParams

export type InstitutionUnitResponse = {
  item: {
    id: string
    name: string
    cnpj: string
    street: string
    complement?: string
    number?: string
    neighborhood: string
    city: string
    state: {
      id: string
      name: string
    }
    cep: string
    reference?: string
    lat: string
    long: string
    image?: string
  }
}

const institutionUnitQuery = gql`
  query InstitutionUnit($id: ID!) {
    item: institutionUnit(id: $id) {
      id
      name
      cnpj
      street
      complement
      number
      neighborhood
      city
      state {
        id
        name
      }
      cep
      reference
      lat
      long
      image
    }
  }
`

export function useGetInstitutionUnit(props: InstitutionUnitParams) {
  const { data, loading, error } = useQuery<InstitutionUnitResponse, InstitutionUnitQuery>(institutionUnitQuery, {
    variables: {
      id: props.id,
    },
    skip: !props.id,
  })

  return {
    institutionUnitData: data,
    isInstitutionUnitLoading: loading,
    institutionUnitError: error,
  }
}