import { gql, useMutation } from "@apollo/client";

export type EditInstitutionUnitQuery = {
  id: string
  name: string
  cnpj: string
  street: string
  complement?: string
  number?: number
  neighborhood: string
  city: string
  cep: string
  reference?: string
  lat: number
  long: number
  image?: string
  state_id: number
}

const editInstitutionUnitQuery  = gql`
  mutation UpdateInstitutionUnit(
    $id: ID!
    $name: String!
    $cnpj: String!
    $street: String!
    $complement: String
    $number: Int
    $neighborhood: String!
    $city: String!
    $cep: String!
    $reference: String
    $lat: Float!
    $long: Float!
    $image: Upload
    $state_id: ID!
  ) {
    updateInstitutionUnit(
      id: $id
      name: $name
      cnpj: $cnpj
      street: $street
      complement: $complement
      number: $number
      neighborhood: $neighborhood
      city: $city
      cep: $cep
      reference: $reference
      lat: $lat
      long: $long
      image: $image
      state_id: $state_id
    ) {
      id
      name
      cnpj
      street
      complement
      number
      neighborhood
      city
      state {
        id
        name
      }
      cep
      reference
      lat
      long
      image
      created_at
      updated_at
    }
  }
`

export function useEditInstitutionUnit() {
  const [editInstitutionUnit, { data, loading, error }] = useMutation<any, EditInstitutionUnitQuery>(editInstitutionUnitQuery)

  return {
    institutionUnitData: data,
    isInstitutionUnitLoading: loading,
    institutionUnitError: error,
    editInstitutionUnit,
  }
}