import { CircularProgress, Grid, Icon, Paper, Typography } from "@mui/material"
import { ReactNode } from "react"

type DashboardReportCardProps = {
  title: string
  loading: boolean
  icon: ReactNode
  total?: string | number
}

export function DashboardReportCard(props: DashboardReportCardProps) {
  return (
    <Grid item xs={12} md={6} lg={4}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="p" variant="h4">
          {props.loading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              {props.total}
              {' '}
              <Icon
                fontSize="medium"
              >
                {props.icon}
              </Icon>
            </>
          )}
        </Typography>
        <Typography variant="h6" color="text.secondary" gutterBottom>{props.title}</Typography>
      </Paper>
    </Grid>
  )
}

