import { gql, useMutation } from "@apollo/client";

export type EditInstitutionQuery = {
  id: string
  name: string
  cnpj: string
  username: string
  password?: string
}

const editInstitutionQuery  = gql`
  mutation UpdateInstitution(
    $id: ID!
    $name: String!
    $cnpj: String!
    $username: String!
    $password: String
  ) {
    updateInstitution(
      id: $id
      name: $name
      cnpj: $cnpj
      username: $username
      password: $password
    ) {
      id
      name
    }
  }
`

export function useEditInstitution() {
  const [editInstitution, { data, loading, error }] = useMutation<any, EditInstitutionQuery>(editInstitutionQuery)

  return {
    institutionData: data,
    isInstitutionLoading: loading,
    institutionError: error,
    editInstitution,
  }
}