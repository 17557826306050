import { Breadcrumbs, Grid, Link, Paper, Typography } from "@mui/material";
import { Wrapper } from "../../components/Wrapper";
import { useForm } from "react-hook-form";
import { Form } from "../../components/Form";
import { ConfirmButton } from "../../components/ConfirmButton";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../components/CustomButton";
import { InputField } from "../../components/InputField";
import { useAddInstitution } from "../../hooks/useAddInstitution";
import { ApolloError, useApolloClient } from "@apollo/client";

type InstitutionAddForm = {
  name: string
  cnpj: string
  email: string
  password: string
  passwordConfirmation: string
}

export function InstitutionAddPage() {
  return (
    <Wrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Dashboard
            </Link>
            <Link underline="hover" color="inherit" href="/institutions">
              Instituições
            </Link>
            <Typography color="text.primary">Adicionar Instituição</Typography>
          </Breadcrumbs>
        </Grid>
        
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <InstitutionForm />
          </Paper>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

function InstitutionForm() {
  const client = useApolloClient()
  const navigate = useNavigate()
  const { addInstitution, isInstitutionsLoading } = useAddInstitution()
  const { handleSubmit, control } = useForm<InstitutionAddForm>({
    defaultValues: {
      cnpj: '',
      email: '',
      name: '',
      password: '',
      passwordConfirmation: '',
    }
  })

  return (
    <Form
      control={control}
      onSubmit={handleSubmit((data) => {
        if (data.password !== data.passwordConfirmation) {
          alert('A Senha e a Confirmação de Senha são inválidas')
        } else {
          addInstitution({
            variables: {
              name: data.name,
              cnpj: data.cnpj,
              username: data.email,
              password: data.password,
            }, 
            onCompleted: async () => {
              await client.refetchQueries({
                include: "all",
              })
              alert('Instituição salva com sucesso!')
              navigate('/institutions')
            },
            onError: (err: ApolloError) => {
              // TODO: Show toast on error?
              alert(err.message)
            },
          })
        }
      })}
      sx={{ mt: 1 }}
    >
      <InputField
        name="name"
        rules={{ required: true }}
        label="Nome"
        autoFocus
      />
      <InputField
        name="cnpj"
        rules={{ required: true }}
        label="CNPJ"
        format="cnpj"
      />
      <InputField
        name="email"
        rules={{ required: true }}
        label="Email"
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <InputField 
            name="password"
            rules={{ required: true }}
            label="Senha"
            type="password"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField 
            name="passwordConfirmation"
            rules={{ 
              required: true,
              validate: (value, { password }) => {
                if (value !== password) {
                  return 'A Senha e a Confirmação de Senha não conferem'
                }

                return true
              }
            }}
            label="Confirmação de Senha"
            type="password"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <ConfirmButton
            loading={isInstitutionsLoading}
          >
            Salvar
          </ConfirmButton>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomButton
            color="error"
            disabled={isInstitutionsLoading}
            onClick={() => navigate('/institutions')}
          >
            Cancelar
          </CustomButton>
        </Grid>
      </Grid>
    </Form>
  )
}
