import { Breadcrumbs, Chip, Grid, Link, Typography } from "@mui/material";
import { Wrapper } from "../../components/Wrapper";
import { useGetInstitutions } from "../../hooks/useGetInstitutions";
import { Business, Edit, People, School } from "@mui/icons-material";
import { PaperTable } from "../../components/PaperTable";
import { useNavigate, useSearchParams } from "react-router-dom";

export function InstitutionsPage() {
  const navigate = useNavigate()
  
  const [searchParams] = useSearchParams()
  const page = parseInt(searchParams.get('page') || '1')
  const { institutionsData, isInstitutionsLoading } = useGetInstitutions({
    page,
  })

  return (
    <Wrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Dashboard
            </Link>
            <Typography color="text.primary">Instituições</Typography>
          </Breadcrumbs>
        </Grid>
        <PaperTable 
          title="Instituições"
          addLink="/institutions/add"
          loading={isInstitutionsLoading}
          headers={['Nome', 'CNPJ', 'Ativo']}
          columns={[
            { key: 'name' },
            { key: 'cnpj' },
            {
              key: 'status',
              render: (statusVal) => {
                if (statusVal) {
                  return <Chip label="Ativo" color="success" />
                }

                return <Chip label="Não Ativo" color="error" />
              }
            },
          ]}
          data={institutionsData?.list.institutions ?? []}
          options={[
            {
              icon: <School />,
              label: 'Certificados',
              onClick: () => {
                alert('Em desenvolvimento. Aguarde!')
              },
            },
            {
              icon: <Business />,
              label: 'Unidades',
              onClick: ({ id }) => {
                navigate(`/institutions/${id}/units`)
              },
            },
            {
              icon: <People />,
              label: 'Usuários',
              onClick: () => {
                alert('Em desenvolvimento. Aguarde!')
              },
            },
            { type: 'divider' },
            {
              icon: <Edit />,
              label: 'Editar',
              onClick: ({ id }) => {
                navigate(`/institutions/edit/${id}`)
              },
            }
          ]}
          pagination={institutionsData?.list ? {
            currentPage: institutionsData.list.currentPage,
            totalPages: institutionsData.list.lastPage,
            totalResults: institutionsData.list.totalResults,
          } : undefined}
        />
      </Grid>
    </Wrapper>
  )
}

