import { gql, useLazyQuery } from "@apollo/client";
import { ProgramsReportParams } from "./useGetProgramsReport";

export type ProgramsReportExportParams = {
  format: 'PDF' | 'XLSX'
} & Pick<ProgramsReportParams, 'filter'>

type ProgramsReportExportQuery = ProgramsReportExportParams

type ProgramsReportExportResponse = {
  result: string
}

const programsReportExportQuery = gql`
  query ExportCoursesReport($format: String!, $filter: CoursesReportFilter) {
    result: exportCoursesReport(format: $format, filter: $filter)
  }
`

export function useGetProgramsReportExport() {
  const [exportProgramsReport, { data, loading, error }] = useLazyQuery<ProgramsReportExportResponse, ProgramsReportExportQuery>(programsReportExportQuery)

  return {
    exportProgramsReport,
    programsReportExportData: data,
    isProgramsReportExportLoading: loading,
    programsReportExportError: error,
  }
}