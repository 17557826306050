import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { Wrapper } from "../../components/Wrapper";
import { PaperTable } from "../../components/PaperTable";
import { useGetRoles } from "../../hooks/useGetRoles";

export function RolesPage() {
  const { rolesData, isRolesLoading } = useGetRoles()

  return (
    <Wrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Dashboard
            </Link>
            <Typography color="text.primary">Tipo de Usuários</Typography>
          </Breadcrumbs>
        </Grid>
        <PaperTable 
          title="Tipo de Usuários"
          loading={isRolesLoading}
          headers={['Nome']}
          columns={[
            { key: 'name' },
          ]}
          data={rolesData?.list ?? []}
        />
      </Grid>
    </Wrapper>
  )
}

