import { gql, useQuery } from "@apollo/client";

type FilterTrainingsParams = {
  skip?: boolean
}

type FilterTrainingsQuery = FilterTrainingsParams

type FilterTrainingsResponse = {
  elements: {
    trainings: Array<{
      id: string
      name: string
    }>
  }
}

const filterTrainingsQuery = gql`
  {
    elements: trainings(paginate: false, active: true) {
      trainings {
        id
        name
      }
    }
  }
`

export function useGetFilterTrainings(params: FilterTrainingsParams) {
  const { data, loading, error } = useQuery<FilterTrainingsResponse, FilterTrainingsQuery>(filterTrainingsQuery, {
    skip: params.skip
  })

  return {
    trainingsData: data?.elements.trainings,
    isTrainingsLoading: loading,
    trainingsError: error,
  }
}