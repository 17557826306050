import { BrowserRouter, Route, Routes } from "react-router-dom"
import { ProtectedRoute } from "./components/ProtectedRoute"
import { NotFoundPage } from "./pages/NotFoundPage"
import { CssBaseline, ThemeProvider } from "@mui/material"
import { LoginPage } from "./pages/LoginPage"
import { ApolloProvider } from "@apollo/client"
import { graphQlClient } from "./config/graphql"
import { defaultTheme } from "./config/theme"
import { DashboardPage } from "./pages/DashboardPage"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { InstitutionsPage } from "./pages/InstitutionsPage"
import { InstitutionAddPage } from "./pages/InstitutionAddPage"
import { InstitutionEditPage } from "./pages/InstitutionEditPage"
import { InstitutionUnitsPage } from "./pages/InstitutionUnitsPage"
import { InstitutionUnitAddPage } from "./pages/InstitutionUnitAddPage"
import { InstitutionUnitEditPage } from "./pages/InstitutionUnitEditPage"
import { RolesPage } from "./pages/RolesPage"
import { ProgramsReport } from "./pages/ProgramsReport"
import { RequestsReport } from "./pages/RequestsReport"

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ApolloProvider client={graphQlClient}>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<DashboardPage />} />

                {/* Institutions */}
                <Route path="/institutions" element={<InstitutionsPage />} />
                <Route path="/institutions/add" element={<InstitutionAddPage />} />
                <Route path="/institutions/edit/:institutionId" element={<InstitutionEditPage />} />
                <Route path="/institutions/:institutionId/units" element={<InstitutionUnitsPage />} />
                <Route path="/institutions/:institutionId/units/add" element={<InstitutionUnitAddPage />} />
                <Route path="/institutions/:institutionId/units/edit/:unitId" element={<InstitutionUnitEditPage />} />

                {/* Programs */}
                <Route path="/programs/report" element={<ProgramsReport />} />

                {/* Requests */}
                <Route path="/requests/report" element={<RequestsReport />} />

                {/* Roles */}
                <Route path="/roles" element={<RolesPage />} />
              </Route>
              <Route element={<ProtectedRoute isLoginRoute />}>
                <Route path="/login" element={<LoginPage />} />
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </ApolloProvider>
    </LocalizationProvider>
  )
}
