import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { Wrapper } from "../../components/Wrapper";
import { Delete, Edit } from "@mui/icons-material";
import { PaperTable } from "../../components/PaperTable";
import { useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useGetInstitution } from "../../hooks/useGetInstitution";
import { useGetInstitutionUnits } from "../../hooks/useGetInstitutionUnits";
import { AuthStorage } from "../../utils/auth/AuthStorage";
import { LoadingPage } from "../LoadingPage";
import { useDeleteInstitutionUnit } from "../../hooks/useDeleteInstitutionUnit";
import { ApolloError, useApolloClient } from "@apollo/client";

type InstitutionUnitsParams = {
  institutionId: string
}

export function InstitutionUnitsPage() {
  const client = useApolloClient()
  const { institutionId } = useParams<InstitutionUnitsParams>()
  const { institutionData, isInstitutionLoading } = useGetInstitution({ id: institutionId ? parseInt(institutionId) : undefined })
  const navigate = useNavigate()
  const [page, setPage] = useState<number>(1)
  const { institutionUnitsData, isInstitutionUnitsLoading } = useGetInstitutionUnits({
    id: institutionId,
    page,
  })
  const { deleteInstitutionUnit, isDeleteInstitutionUnitsLoading } = useDeleteInstitutionUnit()
  const user = AuthStorage.getUser()

  if (!institutionId) {
    return <Navigate to="/institutions" replace />
  }

  if (isInstitutionLoading || isInstitutionUnitsLoading || isDeleteInstitutionUnitsLoading) {
    return <LoadingPage />
  }

  if (!institutionData) {
    return <Navigate to="/institutions" replace />
  }

  return (
    <Wrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Dashboard
            </Link>
            <Link underline="hover" color="inherit" href="/institutions">
              Instituições
            </Link>
            <Typography color="text.primary">Unidades{institutionData ? ` - ${institutionData.item.name} #${institutionData.item.id}` : ''}</Typography>
          </Breadcrumbs>
        </Grid>
        <PaperTable 
          title="Unidades"
          addLink={`/institutions/${institutionId}/units/add`}
          loading={isInstitutionUnitsLoading}
          headers={['Nome', 'CNPJ', 'Ativo']}
          columns={[
            { key: 'name' },
            { key: 'cnpj' },
            {
              key: 'address',
              render: (_, { street, neighborhood, city, state }) => {
                return (
                  <>
                    {[street, neighborhood, city, state.name].join(', ')}
                  </>
                )
              }
            },
          ]}
          data={institutionUnitsData?.list.institutionUnits ?? []}
          options={user.role === 'Administrador' || user.role === 'Regional' ? [
            {
              icon: <Edit />,
              label: 'Editar',
              onClick: ({ id }) => {
                navigate(`/institutions/${institutionId}/units/edit/${id}`)
              },
            },
            {
              icon: <Delete />,
              label: 'Deletar',
              onClick: ({ id }) => {
                if (window.confirm('Você deseja remover essa unidade?')) {
                  deleteInstitutionUnit({
                    variables: { id },
                    onCompleted: async () => {
                      await client.refetchQueries({
                        include: "all",
                      })
                    },
                    onError: (err: ApolloError) => {
                      // TODO: Show toast on error?
                      alert(err.message)
                    },
                  })
                }
              },
            },
          ] : []}
          pagination={institutionUnitsData?.list ? {
            currentPage: institutionUnitsData.list.currentPage,
            totalPages: institutionUnitsData.list.lastPage,
            totalResults: institutionUnitsData.list.totalResults,
            onPageChange: setPage,
          } : undefined}
        />
      </Grid>
    </Wrapper>
  )
}

