import { gql, useQuery } from "@apollo/client";

type FilterCertificatesParams = {
  skip?: boolean
}

type FilterCertificatesQuery = FilterCertificatesParams

type FilterCertificatesResponse = {
  elements: {
    certificates: Array<{
      id: string
      name: string
    }>
  }
}

const filterCertificatesQuery = gql`
  {
    elements: certificates(paginate: false, active: true) {
      certificates {
        id
        name
      }
    }
  }
`

export function useGetFilterCertificates(params: FilterCertificatesParams) {
  const { data, loading, error } = useQuery<FilterCertificatesResponse, FilterCertificatesQuery>(filterCertificatesQuery, {
    skip: params.skip
  })

  return {
    certificatesData: data?.elements.certificates,
    isCertificatesLoading: loading,
    certificatesError: error,
  }
}