import { gql, useQuery } from "@apollo/client";

type FilterCompaniesResponse = {
  elements: {
    items: Array<{
      id: string
      key: string
      value: string
    }>
  }
}

const filterCompaniesQuery = gql`
  {
    elements: companies(paginate: false, filter: {}) {
      items: companies {
        id
        key: id
        value: name
      }
    }
  }
`

export function useGetFilterCompanies() {
  const { data, loading, error } = useQuery<FilterCompaniesResponse>(filterCompaniesQuery)

  return {
    companiesData: data?.elements.items || [],
    isCompaniesLoading: loading,
    companiesError: error,
  }
}