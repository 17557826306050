import { MoreVert } from "@mui/icons-material"
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, TableCell } from "@mui/material"
import { ReactNode, useState } from "react"

type PaperTableOptionsItem = {
  type?: 'button' | 'divider'
  icon?: ReactNode
  label?: string
  onClick?: (data: any) => void
}

export type PaperTableOptionsProps = {
  items: Array<PaperTableOptionsItem>
  data: any
}

export function PaperTableOptions(props: PaperTableOptionsProps) {
  const longButtonId = crypto.randomUUID()
  const longMenuId = crypto.randomUUID()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <TableCell>
      <IconButton
        id={longButtonId}
        aria-label="Opções"
        aria-controls={open ? longMenuId : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id={longMenuId}
        MenuListProps={{
          'aria-labelledby': longButtonId,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {props.items.map((item, itemIdx) => {
          if (item.type === 'divider') {
            return <Divider key={`menu-item-${itemIdx}`} />
          }

          return (
            <MenuItem
              key={`menu-item-${itemIdx}`}
              onClick={() => {
                setAnchorEl(null)

                if (item.onClick) {
                  item.onClick(props.data)
                }
              }}
            >
              {item.icon ? (
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
              ) : null}
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          )
        })}
      </Menu>
    </TableCell>
  )
}