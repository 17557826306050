import { Box, Container, Toolbar } from "@mui/material"
import { useState, PropsWithChildren } from "react";
import { CustomDrawer } from "../../components/CustomDrawer";
import { CustomAppBar } from "../../components/CustomAppBar";
import { Copyright } from "../../components/Copyright";

type WrapperProps = PropsWithChildren

export function Wrapper(props: WrapperProps) {
  const [open, setOpen] = useState(true);

  return (
    <Box sx={{ display: 'flex' }}>
      <CustomAppBar
        open={open}
        toggleDrawer={setOpen}
      />
      <CustomDrawer
        open={open}
        toggleDrawer={setOpen}
      />

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {props.children}
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  )
}