import { useSearchParams } from "react-router-dom";

export function useUpdateSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams()

  return {
    setQueryParam: (param: { [queryKey: string]: any }) => {
      const queryParams: any = {}
      searchParams.forEach((value, key) => queryParams[key] = value)

      Object.entries(param).forEach(([queryKey, queryValue]) => {
        queryParams[queryKey] = queryValue
      })

      setSearchParams(queryParams)
    }
  }
}