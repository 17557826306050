import { gql, useQuery } from "@apollo/client";

export type ProgramsReportTypes = 'INTRODUTÓRIO' | 'TREINAMENTO' | 'CERTIFICAÇÃO' | 'TRABALHO'

export type ProgramsReportParams = {
  page: number
  perPage?: number
  filter?: {
    startAt?: string
    finishAt?: string
    type?: ProgramsReportTypes
    training_id?: string
    certificate_id?: string
    job_id?: string
  }
  paginate?: boolean
}

type ProgramsReportQuery = ProgramsReportParams

type ProgramsReportResponse = {
  list: {
    currentPage: number
    firstPage: number
    lastPage: number
    nextPage: number
    prevPage: number
    totalResults: number
    coursesReport: Array<{
      approveds: any
      notApproveds: any
      quantity: any
      date: any
    }>
  }
}

const programsReportQuery = gql`
  query CoursesReport($page: Int, $perPage: Int, $filter: CoursesReportFilter) {
    list: coursesReport(page: $page, perPage: $perPage, filter: $filter) {
      firstPage
      lastPage
      currentPage
      nextPage
      prevPage
      totalResults
      coursesReport {
        approveds
        notApproveds
        quantity
        date
      }
    }
  }
`

export function useGetProgramsReport({
  page,
  perPage = 15,
  filter = {},
  paginate = true,
}: ProgramsReportParams) {
  const { data, loading, error } = useQuery<ProgramsReportResponse, ProgramsReportQuery>(programsReportQuery, {
    variables: {
      page,
      perPage,
      filter,
      paginate,
    },
  })

  return {
    programsReportData: data,
    isProgramsReportLoading: loading,
    programsReportError: error,
  }
}