import { gql, useQuery } from "@apollo/client";

type InstitutionParams = {
  id?: number
}

type InstitutionQuery = InstitutionParams

type InstitutionResponse = {
  item: {
    id: string
    cnpj: string
    name: string
    username: string
  }
}

const institutionQuery = gql`
  query Institution($id: ID!) {
    item: institution(id: $id) {
      id
      name
      cnpj
      username
    }
  }
`

export function useGetInstitution(props: InstitutionParams) {
  const { data, loading, error } = useQuery<InstitutionResponse, InstitutionQuery>(institutionQuery, {
    variables: {
      id: props.id,
    },
    skip: !props.id
  })

  return {
    institutionData: data,
    isInstitutionLoading: loading,
    institutionError: error,
  }
}