import { Box, CircularProgress } from "@mui/material";
import { Wrapper } from "../../components/Wrapper";

export function LoadingPage() {
  return (
    <Wrapper>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2,
        }}
      >
        <CircularProgress />
      </Box>
    </Wrapper>
  )
}