import { gql, useQuery } from "@apollo/client";

export type RequestsReportParams = {
  storeId?: string
  page: number
  gender?: string
  companyId?: string
  hasVacancies?: boolean
  createdAt?: string
  jobId?: string
  date?: string | string[]
  limit?: number
}

type RequestsReportQuery = {
  store_id?: string
  page?: number
  gender?: string
  company?: string
  hasVacancies?: boolean
  created_at?: string
  job?: string
  date?: string | string[]
  limit?: number
}

type RequestsReportResponse = {
  list: {
    currentPage: number
    firstPage: number
    lastPage: number
    nextPage: number
    prevPage: number
    totalResults: number
    requests: Array<{
      id: string | number
      start_at: string
      finish_at: string
      amount: number
      initial_amount: number
      used_amount: number
      status: string
      created_at: string
      trainings: {
        name: string
      }
      certificates: {
        name: string
      }
      store: {
        id: string | number
        name: string
        company: {
          id: string | number
          name: string
        }
      }
      job: {
        id: string | number
        name: string
      }
      gender: string
    }>
  }
}

const requestsReportQuery = gql`
  query Requests(
    $store_id: ID
    $start_at: DateTime
    $finish_at: DateTime
    $page: Int
    $gender: String
    $company: ID
    $hasVacancies: Boolean
    $created_at: DateTime
    $job: ID
    $date: [DateTime]
    $limit: Int
  ) {
    list: requests(
      store_id: $store_id
      start_at: $start_at
      finish_at: $finish_at
      page: $page
      gender: $gender
      company: $company
      hasVacancies: $hasVacancies
      created_at: $created_at
      job: $job
      date: $date
      limit: $limit
    ) {
      firstPage
      lastPage
      currentPage
      nextPage
      prevPage
      totalResults
      requests {
        id
        start_at
        finish_at
        amount
        initial_amount
        used_amount
        status
        created_at
        trainings {
          name
        }
        certificates {
          name
        }

        store {
          id
          name
          company {
            id
            name
          }
        }

        job {
          id
          name
        }
        gender
      }
    }
  }
`

export function useGetRequestsReport({
  companyId,
  createdAt,
  date = [],
  hasVacancies = true,
  jobId,
  page,
  storeId,
}: RequestsReportParams) {
  const { data, loading, error } = useQuery<RequestsReportResponse, RequestsReportQuery>(requestsReportQuery, {
    variables: {
      company: companyId,
      created_at: createdAt,
      date,
      hasVacancies,
      job: jobId,
      page,
      store_id: storeId
    },
  })

  return {
    requestsReportData: data,
    isRequestsReportLoading: loading,
    requestsReportError: error,
  }
}